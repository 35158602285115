.container.testimonials__container {
    width: 30%; 
    padding-bottom: 4rem;   
}
.client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid rgba(77, 181, 255, 0.4);
}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    border-radius: 2rem;
    user-select: none;

}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    widows: 80%;
    margin: 0.8rem 0.8rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
    background: var(--color-primary);
}

/* MEDIA QUERIES MEDIUM DEVICE (FOR TABLETS) */
@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }
  }
  
  
  /* MEDIA QUERIES SMALL DEVICE (FOR MOBILE PHONE) */
  @media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }
    .client__review {
        width: var(--container-width-sm);
    }
  }
  